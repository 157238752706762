import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = 'About Us | Bandeko Uganda Limited';

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2 about-h2">About Us</h2>
      <section className='section-cover bg-cover'>
        <div className='content-container '>
          {/* <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div> */}
           <h3 className='title-h3'>Establishment</h3>
                          
                           <div className='vm-line'></div>

                 <p className='home-p'><strong> Bandeko</strong> is a Lingala word which means friendship. It was established by two friends <em>Mr. Gyagenda Steven Sendi</em> and <em>Mr. Ojiro Joseph Owori</em> who own the company equally and had worked together at Lutor Uganda Limited for a period of 5 years. For the love these two have for the forwarding and transport sector, they saw need to change some ways of work within the sector but could not do so as employees, and so, they decided  to quit their jobs in July 2020. After consultation with a number of clients on their vision of operation and discussion on how they can provide efficient services at friendly costs, they got positive responses, and so, came up with <strong>Bandeko Uganda Limited</strong> which was incorporated by the authorities on the 5<sup>th</sup> day of May 2021.
</p>



 
        </div>

    </section>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>"Operating a disciplined company in the forwarding and transport sector, leading the way to improvement for prompt and efficient services."</p>
                          
                          <h3 className='title-h3'>Mission</h3>
                          <div className='vm-line'></div>
                          <p>"To be the leading provider of good and memorable forwarding and transporting experience to all our clients within and outside the East African region."</p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                       <VisionCarousel></VisionCarousel>
                    </article>

                    <article className='vision-mission-info core-values-a'>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           {/* <CoreValues></CoreValues> */}
           <ul className='values-list'>
            <li>Led by God.</li>
            <li>Passion.</li>
            <li>Loyalty.</li>
            <li>Entrepreneurship.</li>
            <li>Responsibility.</li>
            <li>Integrity.</li>
           </ul>

                    </article>



                 </div>
          </div>
    </section>
    

    </>
  )
}

export default WhoWeAre